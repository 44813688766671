import maintenance from "../maintenance/maintenance.svg";
import React from "react";

export default function Portfolio() {
    return (
        <div className="flex-col content-center justify-center">
            <img src={maintenance} alt="farsan logo 128px" />
        </div>
    );
}
